import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Card } from "react-bootstrap"
import slugify from "slugify"


const PieceListItem = ({ item, pieceType }) => (
  <Card border="light" className="mb-3">
    <Img fluid={item.image.childImageSharp.fluid} />
    <Card.Body>
      <Card.Title>{item.title}</Card.Title>
      <Card.Text className="my-1">
        {item.date}
      </Card.Text>
      <Card.Text className="my-1">
        {item.material}
      </Card.Text>
      <p></p>
      <Card.Text style={{ "text-align": "justify" }}>
        {item.short_desc}
      </Card.Text>
    </Card.Body>
    <Card.Footer className="bg-transparent border-white">
      <Card.Link>
        <Link to={getLinkTarget(pieceType, item.title)} className="stretched-link">
          See more
              </Link>
      </Card.Link>
    </Card.Footer>
  </Card>
)

const getLinkTarget = (pieceType, title) => `/${pieceType}s/${slugify(title)}`

export default PieceListItem
