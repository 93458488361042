import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import PieceListItem from "../components/pieceListItem";
import SEO from "../components/seo";
import { Row, Col } from "react-bootstrap";
import SiteBackground from "../components/siteBackground"

const Sculptures = () => {
  const data = useStaticQuery(graphql`
    query {
      info: allSculptureJson(sort: {fields: sortDate, order: DESC}) {
        nodes {
            date
            title
            size
            material
            desc
            short_desc
            directory
            sortDate
            image: mainImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `)
  const pieceType = "sculpture"
  return (
    <SiteBackground>
      <Layout>
        <SEO title="Elnaz Nourizadeh Sculpture" />
        <h1>Sculptures</h1>
        <Row>
          {data.info.nodes.map(i =>
            <Col md={6} lg={4} className="d-flex">
              <PieceListItem item={i} pieceType={pieceType} />
            </Col>
          )}
        </Row>
      </Layout>
    </SiteBackground>
  )
}

export default Sculptures
